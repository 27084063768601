<template>
  <v-container fluid>
    <v-sheet
      elevation="1"
      class="pa-4"
    >
      <v-card
        elevation="2"
        outlined
        tile
      >
        <v-row dense class="pa-5">
          <v-col cols="4">
            <h4>Tanggal</h4>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="auto"
                v-if="reportParameter.includes('fromdate')"
              >
                <v-menu
                  ref="menuFromDate"
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details
                      outlined
                      class="caption"
                      :placeholder="$_strings.common.FROM_DATE"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="dayjs(fromdate).format('DD-MM-YYYY')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromdate"
                    no-title
                    :max="dayjs(todate).format('YYYY-MM-DD')"
                    scrollable
                    @input="menuFromDate = false"
                    locale="id"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                class="d-none d-sm-flex align-center px-0"
                cols="12"
                sm="auto"
                v-if="reportParameter.includes('fromdate')"
              >-</v-col>
              <v-col cols="auto">
                <v-menu
                  ref="menuToDate"
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details
                      outlined
                      :value="dayjs(todate).format('DD-MM-YYYY')"
                      class="caption"
                      :placeholder="$_strings.common.TO_DATE"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="todate"
                    no-title
                    :min="reportParameter.includes('fromdate') ? dayjs(fromdate).format('YYYY-MM-DD') : null"
                    @input="menuToDate = false"
                    locale="id"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row align="center" justify="center" class="pa-5">
        <v-col cols="1">
          <v-btn color="primary" @click="preview" small>Tampilkan</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'report-filter',
  mounted() {
    const { fromdate, todate, reportParameter } = this.$route.query;
    this.$route.meta.headerTitle = this.$route.query.reportName;
    this.reportParameter = reportParameter.split('|').toString().replace(/\s/g, '').split(',');
    if (!fromdate || !todate) {
      const query = {
        ...this.$route.query,
        todate: this.todate,
      };
      if (reportParameter.includes('fromdate')) query.fromdate = this.fromdate;
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({
          query,
        });
      }
    }
  },
  data() {
    const { fromdate, todate } = this.$route.query;
    return {
      menuFromDate: false,
      menuToDate: false,
      fromdate: fromdate || dayjs().subtract('30', 'days').format('YYYY-MM-DD'),
      todate: todate || dayjs().format('YYYY-MM-DD'),
      reportType: {},
      reportParameter: [],
    };
  },
  watch: {
    fromdate(newVal) {
      const { fromdate } = this.$route.query;
      if (fromdate !== newVal) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            fromdate: newVal,
          },
        });
      }
    },
    todate(newVal) {
      const { todate } = this.$route.query;
      if (todate !== newVal) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            todate: newVal,
          },
        });
      }
    },
  },
  methods: {
    dayjs,
    preview() {
      this.$router.push({
        name: 'report-detail',
        query: {
          ...this.$route.query,
        },
      });
    },
  },
};

</script>
